<template>
	<div class="row">
	  <div class="col-xl-3">
	    <h1>Formularz kierunki badań</h1>

	    <b-form-group label="Parametr">
	      <b-form-input v-model="control.parameter" />
	    </b-form-group>

	    <b-form-group label="Metoda badawcza">
	      <b-form-input v-model="control.method" />
	    </b-form-group>

	    <b-form-group label="Matryca">
	      <b-form-select
            v-model="control.matrix"
            :options="[
              {value: 'serologia', text: 'Serologia',},
              {value: 'weterynaria', text: 'Weterynaria',},
              {value: 'pasza', text: 'Pasza',},
              {value: 'zywnosc', text: 'Żywność',},
              {value: 'woda', text: 'Woda',},
              {value: 'srodowisko', text: 'Środowisko',},
              {value: 'urzedowe', text: 'Urzędowe'},
            ]"
          ></b-form-select>
	    </b-form-group>
	  </div>

	  

	  <div class="col-xl-3">
	  	<b-button @click="storeData" variant="primary" class="mt-3"
	      >Zapisz</b-button
	    >
	  </div>
	</div>
</template>

<script>
/*
<div class="col-xl-8">
	  	<h1>Konfiguracja kart badań</h1>
	  	<div class="accordion" role="tablist">
    		<b-card no-body class="mb-1">
					<b-card-header header-tag="header" class="p-1" role="tab">
						<b-button block v-b-toggle.accordion-1 variant="primary">
							Pożywki wykorzystywane w badaniu
						</b-button>
					</b-card-header>
					<b-collapse
						id="accordion-1"
						visible
						accordion="my-accordion"
						role="tabpanel"
					>
							<div class="p-4">
								<b-card-text
	                >Wybierz pożywki, które będą wykorzystywane w badaniach ( kolejność wyboru ustala kolejność wyświetlania w karcie badania )</b-card-text
	              >
								<treeselect
								  key="trs1"
								  :value-consists-of="'ALL'"
								  :multiple="true"
								  :clearable="true"
								  :searchable="true"
								  :disable-branch-nodes="true"
								  instanceId="trs1"
								  :options="availableSoilCards"
								  placeholder="Wybierz pożywki..."
								  @input="treeselectUpdateValue"
								  :value="treeselectGetValue('trs1')"
								/>
							</div>
              <!-- Tutaj drag and drop pożywek wybranych w tree select :)  -->
    			</b-collapse>
  			</b-card>

  			<b-card no-body class="mb-1">
					<b-card-header header-tag="header" class="p-1" role="tab">
						<b-button block v-b-toggle.accordion-2 variant="primary">
							Parametry dla których sporządzane są wyniki
						</b-button>
					</b-card-header>
					<b-collapse
						id="accordion-2"
						visible
						accordion="my-accordion"
						role="tabpanel"
					>
						<div class="p-4">
							<b-card-text
	                >Wprowadź nazwy parametrów dla których zapisywane będą wyniki na karcie badania ( max 5 )</b-card-text
	              >
	              	<b-button @click="addRow('resultParams')" variant="primary" class="mb-3">Dodaj wiersz</b-button>
	              	<template v-for="(item, index) in  control.lab_chart_config.resultParams" >
	              		<b-form-group :key="'r'+index">
	              		  <b-form-input v-model="control.lab_chart_config.resultParams[index]" />
	              		  <b-button @click="removeRow('resultParams', index)" variant="danger" small class="mb-3">Usuń wiersz</b-button>
	              		</b-form-group>
	              	</template>
						</div>
    			</b-collapse>
  			</b-card>

  			<b-card no-body class="mb-1">
					<b-card-header header-tag="header" class="p-1" role="tab">
						<b-button block v-b-toggle.accordion-3 variant="primary">
							Parametry dla których sporządzane są potwierdzenia wyników
						</b-button>
					</b-card-header>
					<b-collapse
						id="accordion-3"
						visible
						accordion="my-accordion"
						role="tabpanel"
					>
						<div class="p-4">
							<b-card-text
	                >Wprowadź nazwy parametrów dla których zapisywane będą potwierdzenia wyników na karcie badania ( max 5 )</b-card-text
	              >
	              <b-button @click="addRow('resultConfirmationParams')" variant="primary">Dodaj wiersz</b-button>
	              <template v-for="(item, index) in  control.lab_chart_config.resultConfirmationParams" >
	              	<b-form-group :key="'rc'+index">
	              	  <b-form-input v-model="control.lab_chart_config.resultConfirmationParams[index]" />
	              	  <b-button @click="removeRow('resultConfirmationParams', index)" variant="danger" small class="mb-3">Usuń wiersz</b-button>
	              	</b-form-group>
	              </template>
						</div>
    			</b-collapse>
  			</b-card>
  		</div>
	  </div>

*/
	import {mapGetters} from "vuex";
	import "@riophae/vue-treeselect/dist/vue-treeselect.css";
	import {
	  getCardParamValue,
	} from "../../../../core/services/store/soil.module";

	export default {
	  name: "ResearchLinesForm",
	  data() {
	    return {
	      control: {
	        parameter: "",
	        method: "",
	        matrix: "",
	        lab_chart_config: {
	        	soilCards: {
	        		trs1: []
	        	},
	        	soilCardsOrder: [],
	        	resultParams: [],
	        	resultConfirmationParams: []
	        }
	      },
	    };
	  },
	  methods: {
	  	addRow(arr) {
	  		if ( this.control.lab_chart_config[arr].length < 5 )
	  			this.control.lab_chart_config[arr].push('')
	  	},
	  	removeRow(arr, idx) {
	  		this.control.lab_chart_config[arr].splice(idx, 1);
	  	},
	  	getVal(item, param) {
	  	  if (!item) return "";

	  	  return getCardParamValue(item, param);
	  	},
	    storeData() {
	      let payload = {
	        ...this.control,
	        lab_chart_config: JSON.stringify( this.control.lab_chart_config )
	      };

	      if (this.$route.params.id)
	        this.$store.dispatch("researchLinesEdit", {
	          id: this.$route.params.id,
	          ...payload,
	        });
	      else this.$store.dispatch("researchLinesAdd", payload);

	      this.$store.dispatch("researchLinesList");

	      this.$router.push("/lab/researchLine");
	    },
	    treeselectUpdateValue(value, researchLineIndex) {
	      let itemToChange = this.control.lab_chart_config.soilCards[researchLineIndex];
	      itemToChange = value;
	      this.$set(this.control.lab_chart_config.soilCards, researchLineIndex, itemToChange);
	    },
	    treeselectGetValue(researchLineIndex) {
	      let itemToGet = this.control.lab_chart_config.soilCards[researchLineIndex];
	      if (typeof itemToGet === "undefined") {
	        return [];
	      }
	      return itemToGet;
	    },
	  },
	  watch:   {
	    researchLinesRaw: {
	      handler() {
	        let items = this.researchLinesRaw.filter(
	            (el) => el.id == this.$route.params.id
	        );

	        if (items.length > 0)
	          this.control = {
	            parameter: items[0].parameter,
	            method: items[0].method,
	            matrix: items[0].matrix,
	            lab_chart_config: items[0].lab_chart_config || {
			        	soilCards: {
			        		trs1: []
			        	},
			        	soilCardsOrder: [],
			        	resultParams: [],
			        	resultConfirmationParams: []
			        }
	        };
	      },
	      immediate: true,
	    }
	  },
	  computed: {
	    ...mapGetters(["researchLinesRaw", "soilCardsAll", "soilCardsListing"]),
	    availableSoilCards() {
	    	return [
	    		{
	    			id: "pozywki",
	    			label: "Pożywki",
	    			children: this.soilCardsAll.filter( el => el.level == 3 && el.deleted == 0 ).map( el => {
	    				return {
	    					id: el.id,
	    					label:  this.getVal(el, "soilMark") + " / " + this.getVal(el, "soilSerialNo") + " / " + (new Date( Date.parse(this.getVal(el, "soilDryExpiry")) )).toLocaleDateString('pl-PL')
	    				}
	    			})
	    		}
    		];
	    },
	    selectedSoilCards() {
	    	if ( undefined == this.control.lab_chart_config.soilCards.trs1 )
	    		return [];

	    	return [{id:'aaaa', label: "aaaaa bbbbb"}];
	    	// let c = [ ...this.control.lab_chart_config.soilCards.trs1 ];

	    	// return c.map( id => {
	    	// 	let el = this.soilCardsAll.filter( el => el.level == 3 && el.id == id )[0]

	    	// 	return {
	    	// 		id: el.id,
	    	// 		label:  this.getVal(el, "soilMark") + " / " + this.getVal(el, "soilSerialNo") + " / " + (new Date( Date.parse(this.getVal(el, "soilDryExpiry")) )).toLocaleDateString('pl-PL')
	    	// 	}
	    	// })
	    }
	  },
	  mounted() {
	    this.$store.dispatch("researchLinesList");
	    this.$store.dispatch("soilCardsList");
	  },
	};
</script>

