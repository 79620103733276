var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-3"},[_c('h1',[_vm._v("Formularz kierunki badań")]),_c('b-form-group',{attrs:{"label":"Parametr"}},[_c('b-form-input',{model:{value:(_vm.control.parameter),callback:function ($$v) {_vm.$set(_vm.control, "parameter", $$v)},expression:"control.parameter"}})],1),_c('b-form-group',{attrs:{"label":"Metoda badawcza"}},[_c('b-form-input',{model:{value:(_vm.control.method),callback:function ($$v) {_vm.$set(_vm.control, "method", $$v)},expression:"control.method"}})],1),_c('b-form-group',{attrs:{"label":"Matryca"}},[_c('b-form-select',{attrs:{"options":[
              {value: 'serologia', text: 'Serologia',},
              {value: 'weterynaria', text: 'Weterynaria',},
              {value: 'pasza', text: 'Pasza',},
              {value: 'zywnosc', text: 'Żywność',},
              {value: 'woda', text: 'Woda',},
              {value: 'srodowisko', text: 'Środowisko',},
              {value: 'urzedowe', text: 'Urzędowe'} ]},model:{value:(_vm.control.matrix),callback:function ($$v) {_vm.$set(_vm.control, "matrix", $$v)},expression:"control.matrix"}})],1)],1),_c('div',{staticClass:"col-xl-3"},[_c('b-button',{staticClass:"mt-3",attrs:{"variant":"primary"},on:{"click":_vm.storeData}},[_vm._v("Zapisz")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }